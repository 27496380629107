<template>
  <div class="account-edit">
    <div class="main">
      <van-form @submit="onSubmit"
                ref="vanForm"
                @failed="onFail">
        <!-- -->
        <van-field v-model="accountForm.code"
                   v-if="accountForm.type == 'OTHER' && !$route.query.id"
                   label="商品条码">
          <template #input>
            <van-icon class="text-button"
                      name="scan"
                      @click="scanIconBtn" />
          </template>
        </van-field>
        <!-- 资产编码 -->
        <van-field v-model="accountForm.code"
                   required
                   label="资产编码"
                   placeholder="请填写"
                   :disabled="infoDisable"
                   name="asyncValidator"
                   :rules="[
            {
              validator: asyncValidator,
              required: true,
              message: (value, rule) => {
                if (!value) return '资产编码不能为空';
                return '编码已存在';
              }
            }
          ]" />
        <van-field v-model="accountForm.name"
                   name="资产名称"
                   required
                   label="资产名称"
                   placeholder="请填写"
                   :disabled="infoDisable"
                   :rules="[{ required: true, message: '资产名称不能为空' }]" />
        <van-field v-model="accountForm.brandName"
                   name="品牌名称"
                   label="品牌名称"
                   placeholder="请填写" />
        <van-field name="资产分类"
                   label="资产分类"
                   placeholder="请选择"
                   readonly
                   @click="pickerBtn('assetCategory')">
          <template #input>
            <div>{{ accountForm.assetCategory }}</div>
          </template>
        </van-field>
        <van-field v-model="accountForm.specifications"
                   name="规格型号"
                   label="规格型号"
                   placeholder="请填写" />
        <van-field v-model="accountForm.manufacturer"
                   name="所属单位"
                   label="所属单位"
                   placeholder="请填写" />
        <van-field name="计量单位"
                   clickable
                   label="计量单位"
                   placeholder="请选择"
                   readonly
                   @click="pickerBtn('measuringUnit')">
          <template #input>
            <div>{{ accountForm.measuringUnit }}</div>
          </template>
        </van-field>
        <van-field v-model="accountForm.price"
                   maxlength="10"
                   onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);"
                   name="商品价格"
                   label="商品价格"
                   placeholder="请填写" />
        <van-field v-model="accountForm.storagePlace"
                   name="存放位置"
                   required
                   label="存放位置"
                   placeholder="请填写"
                   :rules="[{ required: true, message: '存放位置不能为空' }]" />

        <van-field v-model="accountForm.warrantyPeriod"
                   name="保修期至"
                   label="保修期至"
                   placeholder="请选择"
                   readonly
                   @click="showCalendarBtn('single')" />

        <van-field v-model="accountForm.barcode"
                   name="资产条码"
                   label="资产条码"
                   placeholder="请填写" />
        <van-field name="资产状态"
                   label="资产状态"
                   placeholder="请选择"
                   readonly
                   :disabled="statusDisable"
                   @click="pickerBtn('status', statusDisable)">
          <template #input>
            <div :class="{ disabled: statusDisable }">{{ accountForm.status | getStatus }}</div>
          </template>
        </van-field>
        <van-field name="资产申请"
                   label="资产申请"
                   placeholder="请选择"
                   readonly
                   :disabled="statusDisable">
          <template #input>
            <van-checkbox v-model="accountForm.mayBorrow">
              {{accountForm.mayBorrow?"可申请":"不可申请"}}
            </van-checkbox>
          </template>
        </van-field>
        <van-field name="使用状态"
                   label="使用状态"
                   placeholder="请选择"
                   readonly
                   :disabled="editDisable"
                   @click="pickerBtn('usingStatus', editDisable)">
          <template #input>
            <div :class="{ disabled: editDisable }">{{ accountForm.usingStatus | getUsingStatus }}</div>
          </template>
        </van-field>
        <van-field v-if="accountForm.usingStatus != 'AVAILABLE'"
                   v-model="accountForm.organizationName"
                   name="使用单位"
                   required
                   label="使用单位"
                   placeholder="请填写"
                   :disabled="editDisable"
                   @click="pickerBtn('organizationName', editDisable)"
                   :rules="[{ required: true, message: '使用单位不能为空' }]" />
        <van-field v-if="accountForm.usingStatus == 'TEMPORARY_LENDING'"
                   name="validator"
                   required
                   validate-trigger="onChange"
                   label="出借时间"
                   placeholder="请选择"
                   :disabled="editDisable"
                   :rules="[
            {
              validator: () => {
                if (accountForm.lendingTime && accountForm.lendingTime.length) return true;
                return false;
              },
              trigger: 'onChange',
              message: '出借时间不能为空'
            }
          ]"
                   @click="showCalendarBtn('range', editDisable)">
          <template #input>
            <div :class="{ disabled: editDisable }"
                 v-if="accountForm.lendingTime && accountForm.lendingTime.length">
              {{ accountForm.lendingTime[0] }}~{{ accountForm.lendingTime[1] }}
            </div>
          </template>
        </van-field>
        <van-field v-model="accountForm.remark"
                   name="备注"
                   label="备注"
                   placeholder="请填写" />
        <van-field name="所属仓库"
                   label="所属仓库"
                   required
                   placeholder="请选择"
                   readonly
                   v-model="accountForm.warehousing"
                   :rules="[{ required: true, message: '所属仓库不能为空' }]"
                   @click="pickerBtn('warehousing')">
          <template #input>
            <div>{{ accountForm.warehousing }}</div>
          </template>
        </van-field>
        <div>
          <van-field name="图片"
                     label-width="60px"
                     label="图片">
            <template #input>
              <!-- <van-uploader v-model="fileList" :before-delete="uploadRemove" multiple /> -->
              <van-uploader class="item-uploader"
                            slot="input"
                            v-model="fileList"
                            :before-read="beforeRead"
                            :preview-options="{ closeable: true, images: fileList.map(item => item.url) }"
                            :after-read="afterRead"
                            :before-delete="beforeDelete">
                <template #preview-cover="file">
                  <van-checkbox class="cover-checkbox"
                                @click.stop.native="handleCoverCheckboxClick(file)"
                                v-model="file.isCover"
                                icon-size="18px">封面</van-checkbox>
                </template>
              </van-uploader>
            </template>
          </van-field>
        </div>

        <!-- <div>领用/借用记录</div> -->
        <van-collapse v-model="collapseList">
          <van-collapse-item v-for="item in lendsList"
                             :key="item.id"
                             :title="item.assetLending.lendingNumber"
                             :name="item.id">
            <div class="content">
              <div class="content-line">
                <span class="name">使用状态:</span>
                <span class="value"
                      v-if="item.assetLending.usingStatus == 'RECIPIENT'">领用</span>
                <span class="value"
                      v-if="item.assetLending.usingStatus == 'LENDING'">借出</span>
              </div>
              <div class="content-line">
                <span class="name">单位:</span>
                <span class="value">{{ item.assetLending.organizationName }}</span>
              </div>
              <div class="content-line">
                <span class="name">借用人:</span>
                <span class="value">{{ item.assetLending.pickName }}</span>
              </div>
              <div class="content-line">
                <span class="name">申请时间:</span>
                <span class="value">{{ item.assetLending.creationTime }}</span>
              </div>
              <div class="content-line">
                <span class="name">预计归还日期:</span>
                <span class="value">{{ item.assetLending.lendingEndDate }}</span>
              </div>
            </div>
          </van-collapse-item>
        </van-collapse>
        <div class="action">
          <van-button class="save"
                      round
                      block
                      type="info"
                      :disabled="isClick"
                      native-type="submit">保存</van-button>
        </div>
      </van-form>

      <!-- 选择弹框 -->
      <van-popup v-model="showPopup"
                 round
                 position="bottom">
        <custom-popup :title="pickerTitle"
                      :columns="pickerColumns"
                      @cancel="showPopup = false"
                      @confirm="pickerConfirm"></custom-popup>
      </van-popup>
      <!-- 选择日期 -->
      <van-calendar v-model="showCalendar"
                    :type="calendarType"
                    @confirm="onConfirm" />
    </div>
  </div>
</template>

<script>
import "@/assets/less/views/assets/accountEdit.less";

import { SUCCESS_CODE } from "@/apis/notification";
import wxapi from "@/lib/wechat";
import { flatMap, find, pick, assign } from "lodash-es";
let vm;
export default {
  name: "CommodityDetail",
  data() {
    return {
      statusDisable: false,
      editDisable: false,
      infoDisable: false,
      showPopup: false,
      showCalendar: false,
      isClick: false,
      calendarType: "",
      accountForm: {
        code: "", //编号
        name: "", //资产名称
        assetCategory: "", //资产分类
        specifications: "", //规格型号
        measuringUnit: "", //计量单位
        brandName: "", //品牌名称
        price: null, //价格
        manufacturer: "", //生产厂商
        barcode: "", //条码
        remark: "", //备注
        status: "ENABLE", //状态(ENABLE(启用),REPAIR(报修),DISCARDED(报废))
        storagePlace: "", //存放地点
        warrantyPeriod: "", //保修期至
        organizationName: "",
        lendingTime: [], //出借时间
        assetLendingId: "", //
        usingStatus: "AVAILABLE", //使用状态(SECULAR_RECIPIENT(长期领用),TEMPORARY_LENDING(临时借出))
        attachments: [],
        mayBorrow: true,
        type: "OTHER" //类型(FIXED(固定资产),OTHER(其它资产))
      },
      pickerColumns: [],
      pickerTitle: "",
      fileList: [],
      // deleInfo: [],
      lendsList: [],
      collapseList: []
    };
  },
  methods: {
    async init() {
      vm = this;
      this.initData();
      let { info } = this.$route.params;
      await this.getDictsList();
      await this.getOrganizationList();
      if (this.$route.query.id) {
        this.infoDisable = true;
        this.getAssetsId(this.$route.query.id);
        this.getAssetsIdLends(this.$route.query.id);
      }
      if (info) {
        this.infoDisable = true;
        let code = this.getInfoValue("卡片编号");
        let ret = await this.getAssets(code);
        let assetCategory = this.getInfoValue("资产分类代码");
        let assetsObj = ret.data[0] || {};
        if (ret.data.length > 0) {
          this.id = ret.data[0].id;
          if (ret.data[0].assetLendingId) {
            this.getAssetLendingsId(ret.data[0].assetLendingId);
          }
          this.getAssetsIdLends(this.id);
        }
        // 字典是否存在 资产分类
        if (assetCategory) {
          this.addAssetsList(assetCategory);
        }
        this.accountForm = Object.assign(assetsObj, {
          code, //"S1211312222",
          name: this.getInfoValue("资产名称"), //"资产名称",
          assetCategory: assetCategory,
          specifications: this.getInfoValue("规格型号"), //"规格型号",
          measuringUnit: this.getInfoValue("计量单位"), //"计量单位",
          brandName: "", //品牌名称
          price: this.getInfoValue("原值"), //10,
          manufacturer: this.getInfoValue("所属单位"), //"所属单位",
          barcode: "", //"123",
          remark: this.getInfoValue("备注"), //"备注",
          status: "ENABLE", //"ENABLE", //状态(ENABLE(启用),REPAIR(报修),DISCARDED(报废))
          storagePlace: this.getInfoValue("存放地点"), // "存放地点",
          warrantyPeriod: "", //"2022-05-16", //保修期至
          lendingTime: [], //出借时间
          usingStatus: "AVAILABLE", //"SECULAR_RECIPIENT", //使用状态(SECULAR_RECIPIENT(长期领用),TEMPORARY_LENDING(临时借出))
          type: "OTHER",
          mayBorrow: true,
          attachments: []
        });
      }
      if (this.$route.query.type == "OTHER") {
        this.accountForm.type = "OTHER";
      }
    },
    initData() {
      this.assetsList = [];
      this.meteringList = [];
      this.fileList = [];
      // this.deleInfo = [];
      this.lendsList = [];
      this.infoDisable = false;
      this.editDisable = false;
      this.statusDisable = false;
      this.isClick = false;
    },
    // 获取资产详情
    async getAssetsId(id) {
      let ret = await this.$api.getAssetsId({ placeholder: { id } });
      let fileList = ret.data.attachments;
      delete ret.data.attachments;

      this.accountForm = ret.data;
      // 资产状态 报废禁止编辑
      if (ret.data.status == "DISCARDED") {
        this.statusDisable = true;
      }
      if (fileList.length) {
        this.fileList = await this.getAppendixes(fileList);
      }
      if (ret.data.assetLendingId) this.getAssetLendingsId(ret.data.assetLendingId);
    },
    // 获取字典
    async getDictsList() {
      let ret = await this.$api.getDictsList({
        params: {
          page: false
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      this.assetsList = ret.data["资产分类"][0].dictOptions;
      this.dictAssetsId = ret.data["资产分类"][0].id;
      this.meteringList = ret.data["计量单位"][0].dictOptions;
      this.warehouseOptions = ret.data["所属仓库"][0].dictOptions;
    },
    // 获取 使用单位列表
    async getOrganizationList() {
      let ret = await this.$api.getOrganizationList();
      if (ret.code !== SUCCESS_CODE) return;
      this.organizationList = ret.data;
    },
    // 编辑资产
    async putAssetsId(params, id, type) {
      let ret = await this.$api.putAssetsId({ params, placeholder: { id } });
      if (ret.code != SUCCESS_CODE) return;

      this.addAssetsAttachments(id);

      if (this.accountForm.usingStatus !== "AVAILABLE" && !this.editDisable) {
        this.setAssetLendings(id);
        return false;
      }
      this.$utils.success("编辑成功");
      setTimeout(async () => {
        await this.$router.go(-1);

        setTimeout(() => {
          window.scrollTo(0, 0);
          this.$parent.$refs[window.currentRoute].init();
        }, 500);
      }, 1500);
    },
    //获取解密图片url
    async getAppendixes(par) {
      let urls = par
        .map(val => {
          return val.attachmentUrl;
        })
        .join(",");
      let ret = await this.$api.getAppendixFilePath({ params: { urls } });
      return par.map((val, ind) => {
        return { ...val, url: ret.data[ind] };
      });
    },
    // 删除服务器图片
    async deleteAppendixes(url) {
      let ret = await this.$api.deleteAppendixes({ placeholder: { url } });
    },
    // 删除资产图片
    async deleteAssetsAttachments(id, attachmentId) {
      let ret = await this.$api.deleteAssetsAttachments({ placeholder: { id, attachmentId } });
    },
    //获取资产借出/领用记录
    async getAssetsIdLends(id) {
      let ret = await this.$api.getAssetsIdLends({ placeholder: { id } });
      this.lendsList = ret.data;
      if (this.lendsList.length > 0) {
        let isFlag = this.lendsList.every(val => {
          return val.giveBack;
        });
        this.editDisable = !isFlag;
      }
    },
    // 根据 资产编码code 查询资产详情
    async getAssets(code) {
      let params = { page: true };
      params.query = "(code=" + code + ")";
      return await this.$api.getAssets({ params });
    },
    // 导入资产
    async postAssets(params) {
      let ret = await this.$api.postAssets({ params });
      if (ret.code != SUCCESS_CODE) return;
      if (this.accountForm.usingStatus == "AVAILABLE") {
        this.$utils.success("导入成功");
        setTimeout(async () => {
          await this.$router.go(-1);

          setTimeout(() => {
            window.scrollTo(0, 0);
            this.$parent.$refs[window.currentRoute].init();
          }, 500);
        }, 1500);
        return ret.data.id;
      }

      this.setAssetLendings(ret.data.id);
      return ret.data.id;
    },

    // 获取资产帐-使用单位详情
    async getAssetLendingsId(id) {
      let ret = await this.$api.getAssetLendingsId({ placeholder: { id } });
      if (ret.data.usingStatus == "LENDING") {
        this.$set(this.accountForm, "lendingTime", [ret.data.lendingStartDate, ret.data.lendingEndDate]);
      }
      this.$set(this.accountForm, "organizationName", ret.data.organizationName);
    },
    // 设置资产帐-使用单位
    async setAssetLendings(id) {
      if (this.editDisable) return;
      let ret = await this.postAssetLendings(id);
      if (ret.code == "ASSET.CODE_DOES_EXIST") this.$utils.warning("编码已存在");
      if (ret.code != SUCCESS_CODE) return;
      let res = this.putAssetsUsingStatus([id]);
      if (res.code != SUCCESS_CODE) return;
      this.$utils.success("导入成功");
    },
    // 新增资产帐-使用单位
    async postAssetLendings(id) {
      let params = {
        organizationName: this.accountForm.organizationName,
        storagePlace: this.accountForm.storagePlace,
        items: [{ assetId: id }]
      };
      if (this.accountForm.usingStatus == "SECULAR_RECIPIENT") params.usingStatus = "RECIPIENT";
      if (this.accountForm.usingStatus == "TEMPORARY_LENDING") params.usingStatus = "LENDING";
      if (params.usingStatus == "LENDING" && this.accountForm.lendingTime.length) {
        params.lendingStartDate = this.accountForm.lendingTime[0];
        params.lendingEndDate = this.accountForm.lendingTime[1];
      } else if (params.usingStatus == "RECIPIENT") {
        params.lendingStartDate = this.getDateTime();
      }
      return await this.$api.postAssetLendings({
        params
      });
    },
    // 修改资产帐使用状态
    async putAssetsUsingStatus(ids) {
      return await this.$api.putAssetsUsingStatus({
        params: { ids, usingStatus: this.accountForm.usingStatus }
      });
    },
    //新增 资产分类 字典
    addAssetsList(assetCategory) {
      let isFlag = this.assetsList.some(val => {
        return val.name == assetCategory;
      });
      if (!isFlag) {
        this.assetsList.push({
          value: assetCategory,
          alias: assetCategory
        });
        // this.addDictOptions();
      }
    },
    // 图片上传
    postAppendixFilePath(files) {
      let ret = this.$api.postAppendixFilePath({ params: files });
      return ret;
    },

    //获取导入数据
    getInfoValue(key) {
      let { info, value } = this.$route.params;
      info.forEach(val => {
        if (val.name == key) {
          value = val.value;
        }
      });
      return value;
    },
    getDateTime() {
      // 获取当前日期
      let timestamp = Date.parse(new Date());
      let date = new Date(timestamp);
      //获取年份
      var Y = date.getFullYear();
      //获取月份
      var M = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      //获取当日日期
      var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let todayDate = Y + "-" + M + "-" + D;
      return todayDate;
    },

    pickerBtn(type, isClick = false) {
      if (isClick) return;
      this.showPopup = true;
      if (type == "measuringUnit") {
        this.pickerTitle = "计量单位";
        this.pickerColumns = this.meteringList.map(val => {
          return { text: val.value, key: "measuringUnit", id: val.id };
        });
      }
      if (type == "assetCategory") {
        this.pickerTitle = "资产分类";
        this.pickerColumns = this.assetsList.map(val => {
          return { text: val.value, key: "assetCategory", id: val.value };
        });
      }
      if (type == "status") {
        this.pickerTitle = "资产状态";
        this.pickerColumns = [
          { text: "正常", key: "status", id: "ENABLE" },
          { text: "报修", key: "status", id: "REPAIR" },
          { text: "报损", key: "status", id: "DAMAGED" },
          { text: "报废", key: "status", id: "DISCARDED" }
        ];
      }
      if (type == "usingStatus") {
        this.pickerTitle = "使用状态";
        this.pickerColumns = [
          { text: "可用", key: "usingStatus", id: "AVAILABLE" },
          { text: "长期领用", key: "usingStatus", id: "SECULAR_RECIPIENT" },
          { text: "临时借出", key: "usingStatus", id: "TEMPORARY_LENDING" },
          { text: "长期领用审核中", key: "usingStatus", id: "SECULAR_RECIPIENT_UNDER_REVIEW", disabled: true },
          { text: "临时借出审核中", key: "usingStatus", id: "TEMPORARY_LENDING_UNDER_REVIEW", disabled: true }
        ];
      }
      if (type == "organizationName") {
        this.pickerTitle = "使用单位";
        this.pickerColumns = this.organizationList.map(val => {
          return { text: val.name, key: "organizationName", id: val.id };
        });
      }
      if (type == "warehousing") {
        this.pickerTitle = "所属单位";
        this.pickerColumns = this.warehouseOptions.map(val => {
          return { text: val.value, key: "warehousing", id: val.value };
        });
      }
    },
    pickerConfirm(val) {
      this.accountForm[val.key] = val.id;
      this.showPopup = false;
    },

    // uploadRemove(file, detail) {
    //   if (file.id) {
    //     let find = this.fileListCopy.findIndex(val => val.id == file.id);
    //     this.deleInfo.push({
    //       url: this.fileListCopy[find].attachmentUrl,
    //       id: this.fileListCopy[find].assetId,
    //       attachmentId: file.id
    //     });
    //   }
    //   this.fileList = this.fileList.filter((val, index) => {
    //     return index != detail.index;
    //   });
    // },

    showCalendarBtn(type, isClick = false) {
      if (isClick) return false;
      this.showCalendar = true;
      this.calendarType = type;
    },
    onConfirm(date) {
      this.showCalendar = false;
      if (this.calendarType == "single") {
        this.accountForm.warrantyPeriod = this.$moment(date).format("YYYY-MM-DD");
      }
      if (this.calendarType == "range") {
        this.accountForm.lendingTime = [this.$moment(date[0]).format("YYYY-MM-DD"), this.$moment(date[1]).format("YYYY-MM-DD")];
        this.$refs.vanForm.submit();
      }
    },
    onFail(arg) {
      arg.errors.forEach(item => {
        this.$notify({ message: item.message, type: "warning" });
      });
    },
    async getFile() {
      let formData = new FormData();
      let ind = 0;
      this.fileList.forEach(val => {
        if (val.file) {
          formData.append(`files[${ind}]`, val.file);
          ind++;
        }
      });
      if (ind) {
        formData.append("type", "ASSET");
        let ret = await this.postAppendixFilePath(formData);
        return this.fileList
          .filter(val => {
            return !val.id;
          })
          .map((val, index) => {
            return {
              assetId: "",
              attachmentUrl: ret.data[index],
              status: "ENABLE",
              type: "image",
              suffix: val.file.name.split(".")[val.file.name.split(".").length - 1]
            };
          });
      } else {
        return [];
      }
    },
    async onSubmit() {
      if (this.isClick) return;
      this.isClick = true;
      let params = { ...this.accountForm, usingStatus: "AVAILABLE" };
      let { id } = this.$route.query;
      if (id) {
        this.putAssetsId(params, id);
      } else {
        if (this.id) {
          this.putAssetsId(params, this.id);
          return;
        }
        let assetId = await this.postAssets(params);
        this.addAssetsAttachments(assetId);
      }
    },

    scanIconBtn() {
      let query;
      wx.scanQRCode({
        needResult: 1,
        scanType: ["qrCode", "barCode"],
        success: res => {
          query = res.resultStr;
          this.getStockItemList(query);
        }
      });
      // query = "6901334200613";
      // this.getStockItemList(query);
    },
    // 物品数据查询
    async getStockItemList(query) {
      let barcode = query.replace(/(\n)/g, "");
      let ret = await this.$api.getStockItemList({
        params: {
          barcode
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      if (ret.data.length > 0) {
        this.shopInfo = ret.data[0];
        this.setInfo();
      } else {
        this.getStockItemByBarCode(barcode);
      }
    },
    // 腾讯云 物品测试
    async getStockItemByBarCode(barcode) {
      let ret = await this.$api.getStockItemByBarCode({
        params: {
          barCode: barcode
        }
      });
      this.shopInfo = ret.data;
      this.setInfo();
    },
    setInfo() {
      this.accountForm.name = this.shopInfo.name;
      this.accountForm.specifications = this.shopInfo.specifications;
      this.accountForm.price = this.shopInfo.unitPrice;
      this.accountForm.assetCategory = this.shopInfo.stockItemCategoryName;
      if (this.shopInfo.stockItemCategoryName) {
        let assetsList = this.assetsList.map(val => {
          return val.value;
        });
        if (assetsList.includes(this.shopInfo.stockItemCategoryName)) {
          this.addAssetsList(this.shopInfo.stockItemCategoryName);
        }
      }
    },
    // 异步校验函数返回 Promise
    async asyncValidator(val) {
      let ret = await this.getAssets(val);
      if (ret.data.length > 0 && !this.infoDisable) {
        return false;
      } else {
        return true;
      }
    },
    handleCoverCheckboxClick(file) {
      setTimeout(() => {
        let idx = this.fileList.findIndex(item => {
          return item.attachmentUrl == file.attachmentUrl;
        });

        this.$set(this.fileList, idx, file);
        let assetId = this.$route.query.id;
        if (assetId) {
          this.saveAssetsAttachments(assetId, file);
        }

        // 如果是勾选，则请取消其他勾选封面
        if (!file.isCover) return;
        this.fileList.forEach((item, idx) => {
          if (!item.isCover || file.attachmentUrl == item.attachmentUrl) return;
          item.isCover = false;
          this.$set(this.fileList, idx, item);
          if (assetId) {
            this.saveAssetsAttachments(assetId, item);
          }
        });
      });
    },
    async saveAssetsAttachments(id, attachmentInfo) {
      if (!id || !attachmentInfo.id) return;
      let params = pick(attachmentInfo, ["id", "attachmentUrl", "status", "type", "suffix", "isCover"]);
      let ret = await this.$api.saveAssetsAttachmentById({
        params,
        placeholder: {
          id
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      this.$utils.success("修改成功");
    },
    async addAssetsAttachments(id) {
      let params = this.fileList
        .filter(item => !item.id)
        .map(item => {
          return assign({ assetId: id }, pick(item, ["attachmentUrl", "status", "type", "suffix", "isCover"]));
        });
      if (!params) return true;
      let ret = await this.$api.addAssetsAttachments({
        params,
        placeholder: {
          id
        }
      });
      if (ret.code != SUCCESS_CODE) return;

      ret.data.forEach(item => {
        let idx = this.fileList.findIndex(it => {
          return item.attachmentUrl == it.attachmentUrl;
        });
        if (idx == -1) return;
        this.fileList[idx].id = item.id;
      });
      return true;
    },
    beforeRead(file) {
      function isOverSize(file) {
        const maxSize = 10 * 1024 * 1024;
        return file.size >= maxSize;
      }

      if (!["image/jpeg", "image/jpg", "image/gif", "image/png"].includes(file.type)) {
        this.$utils.warning("请上传格式图片");
        return false;
      }

      if (isOverSize(file)) {
        this.$utils.warning("文件不能超过5M");
        return false;
      }

      file.isCover = false;

      return true;
    },
    async afterRead(file, detail) {
      let idx = detail.index;
      file.status = "uploading";
      file.message = "上传中...";
      file.isCover = false;

      let formData = new FormData();
      formData.append("file", file.file);
      formData.append("type", "ITEM");
      let ret = await this.$api.postAppendixFilePath({
        params: formData,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      if (ret.code != SUCCESS_CODE) {
        file.status = "failed";
        file.message = "上传失败";
        return;
      }

      let url = ret.data;
      let authUrl = await this.$utils.getAuthFileUrl(url);

      file.status = "done";
      file.message = "";

      this.$set(this.fileList, idx, {
        id: "",
        status: "ENABLE",
        type: "IMAGE",
        suffix: "",
        isCover: false,
        attachmentUrl: url,
        url: authUrl[0]
      });
    },
    async beforeDelete(file, detail) {
      let idx = detail.index;
      let assetId = this.$route.query.id;
      let target = this.fileList[idx];
      await this.$api.deleteAppendixes({
        placeholder: { url: target.attachmentUrl }
      });

      if (!target.id || !assetId) return true;
      let ret = await this.$api.removeAssetsAttachment({
        placeholder: {
          id: assetId,
          attachmentIds: target.id
        }
      });
      if (ret.code != SUCCESS_CODE) return true;
      this.$utils.success("删除成功");
      return true;
    }
  },
  created() {
    this.init();
  },
  mounted() {
    wxapi.ready(["scanQRCode"]).then(res => {
      console.log(res);
    });
  },
  filters: {
    getAssetCategory(val) {
      if (!val) return "";
      let ind = vm.assetsList.findIndex(ret => {
        return ret.id == val;
      });
      return vm.assetsList[ind].value;
    },
    getMeasuringUnit(val) {
      if (!val) return "";
      let ind = vm.meteringList.findIndex(ret => {
        return ret.id == val;
      });
      return vm.meteringList[ind].value;
    },
    getStatus(val) {
      if (val == "ENABLE") return "正常";
      if (val == "REPAIR") return "报修";
      if (val == "DAMAGED") return "报损";
      if (val == "DISCARDED") return "报废";
    },
    getUsingStatus(val) {
      if (val == "AVAILABLE") return "可用";
      if (val == "SECULAR_RECIPIENT") return "长期领用";
      if (val == "TEMPORARY_LENDING") return "临时借出";
      if (val == "SECULAR_RECIPIENT_UNDER_REVIEW") return "长期领用审核中";
      if (val == "TEMPORARY_LENDING_UNDER_REVIEW") return "临时借出审核中";
    }
  }
};
</script>

<style lang="less">
.account-edit {
  .cover-checkbox {
    background-color: rgba(0, 0, 0, 0.8);
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
    padding: 3px;
    color: #fff;

    .van-checkbox__label {
      color: #fff;
    }
  }
}
</style>
